<template>

      <div class="container">
        <!-- Breadcrumb End-->

        <div style="min-height: 400px" class="row ">

            <div class="col-md-2 col-sm-2 ">
             <sidebar/>
           </div>

          <div class="col-lg-10 col-md-10 col-xs-12">

               <div class="form-container content">
                <form>
                <div class="form-group mb-2">
                   <label class="form-label" >Name</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="user.name ? user.name : 'customer'"
                  />
                </div>

                <div class="form-group mb-2">
                   <label class="form-label" >Phone</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="user.mobile_no"
                  />
                </div>

                <div class="form-group mb-2">
                   <label class="form-label" >Email</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="user.email ? user.email : 'No Email Added'"
                  />
                </div>

                <div class="form-group mb-2">
                   <label class="form-label" >City</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="user_city(user.city_id)"
                  />
                </div>
                <div class="form-group mb-2">
                   <label class="form-label" >Address</label>
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    :value="user.address ? user.address : 'Not available'"
                  />
                </div>

                <div class="form-group mb-2 text-center">
                   <router-link style="width: 60px" :to="{name:'UserDashboard'}" class="btn btn-warning back">
                   <i class="bi bi-arrow-left-circle"></i>
                   </router-link>
                </div>
              </form>
              </div>

          </div>
        </div>
      </div>

</template>

<script>
import sidebar from "../../components/Sidebar.vue"

export default {
  // props: ["categories"],
  mounted() {
    this.getCity();
 //   this.headerInject();
    this.$store.dispatch("user");
  },
  data() {
    return {
      city: "",
      cities:[],
    };
  },

  methods: {

    getCity() {
      this.axios.get("get/city/list")
        .then((resp) => {
          // console.log(resp);
          this.cities = resp.data.cities;
        })
      },

      user_city($c_id){
         if (this.cities.length > 0) {
             let city="";
           this.cities.forEach(element => {
              if (element.id==$c_id) {
                // console.log(element.name);
                city = element.name;
              }
           });
          return city ;
         }
      }

  },
  components: {
   sidebar
  },
  computed:{
    user(){
      return this.$store.getters.user ;
    }
  }
};
</script>


<style scoped>


 .form-container{
   margin: 0% 20%;
   width: 60%;
   height: 420px;
   margin-bottom: 50px;
   margin-top: 26px;
   box-shadow:1px 1px 5px 3px #ddd;
 }

.content{
  background:#fff;
  padding: 20px 20px;
}


i {
  font-size:14px;
}
</style>